import Vue from 'vue'
import Vuex from 'vuex'
 
Vue.use(Vuex)
const store = new Vuex.Store({
	state: {
		mstoken: localStorage.getItem('zyxtoken') || '',

	},
	getters: {
		// 全局配置

	},
	mutations: {
		setToken(state, provider) {

			state.mstoken = provider
			localStorage.setItem('zyxtoken', provider);
		},
		
	},
	actions: {
		

    },
});



export default store;