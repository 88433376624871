<template>
  <div id="app">
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <router-view />
    <!-- <Map/> -->
  </div>
</template>

<script>

export default {
  data() {
    return {
    
    }
  },
  mounted() {

  },

  created(){
  
  },
  methods: {
  
  }
}

</script>

<style>
#app {

  width: 100%;
  height: 1080px;
  margin: 0 auto;
}
</style>
