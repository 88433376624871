import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [
  {
    // 设置初始页面
    path: '',
    redirect: '/data-map',
  },
    
  { 
    path: '/data-map', 
    name: 'dataMap',
    component: () => import('../view/dataMap/index.vue')
  },
]
const router = new Router({
  mode: 'hash',
  routes

})

export default router;
