import Vue from 'vue'
import App from './App.vue'
import router from '@/router/index'
import ElementUI from 'element-ui'	
import "element-ui/lib/theme-chalk/index.css";
import '@/assets/styles/common.scss' // global css
import vueSeamlessScroll from 'vue-seamless-scroll'
// 分页组件
import Pagination from "./components/Pagination";
import store from './store/index'
import "@/utils/rem.js"
Vue.prototype.$url =  'https://jxdx.zyxbsxz.com'


Vue.component('Pagination', Pagination)
// import {VueJsonp} from 'vue-jsonp'
// import 'lib-flexible'

// import * as echarts from 'echarts';
 

Vue.config.productionTip = false
 
Vue.use(vueSeamlessScroll)
Vue.use(ElementUI)	
// Vue.use(VueJsonp)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
